import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "link-text"
    }}>{`Link Text`}</h1>
    <p>{`Avoid using “click” or “click here.” Use actionable or descriptive text that best describes what the
link is.`}</p>
    <p>{`Capitalization and punctuation of link text should be consistent. Use these guidelines:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If the entire link is a sentence or sentence fragment, use sentence case for the text.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the link is embedded in other text (such as within a sentence), don’t capitalize the link.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Don’t include closing punctuation unless the link is phrased as a question; in that case, close
with a question mark. If the text is a sentence fragment, don’t use punctuation.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      